<template>
  <div class="form">
    <img class="w-48 h-48" src="@/assets/img/CILO_logo_symbol.png" alt="로고" />
    <div class="center -mt-10">
      <label class="title parula__text-black">Page Not Found</label>
      <label class="sub-title parula__text-black"
        >The page you are looking for doesn't exist</label
      >
    </div>
    <button class="btn parula__bg-black" @click="onHome">
      Back Home
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    onHome: function() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
}

.center {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: xx-large;
  text-align: center;
  margin-top: 22px;
}

.sub-title {
  font-size: large;
  text-align: center;
  margin-top: 2px;
}

.btn {
  border: none;
  width: 10%;
  height: 2.5em;
  margin-top: 2.5rem;
}
</style>
